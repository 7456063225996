import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TwoColsContainer = styled.div`
  display: block;

  @media (min-width: 1024px) {
    display: flex;
    flex: 0 1 auto;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  }

  > * {
    @media (min-width: 1024px) {
      &:first-of-type {
        padding-right: ${({ reverse, theme }) =>
          reverse ? 0 : theme.spacing.lg};

        padding-left: ${({ reverse, theme }) =>
          reverse ? theme.spacing.xxl : 0};
      }
    }
  }
`;

export const TwoCols = ({ reverse, children }) => (
  <TwoColsContainer reverse={reverse}>{children}</TwoColsContainer>
);

TwoCols.propTypes = {
  reverse: PropTypes.bool,
};
