import React, { Component } from 'react';
import styled from 'styled-components';
import loadSirv from '../helpers/loadSirv';

// Component Imports
import { H1, PageSubtitle, Section } from '../components/page-elements';
import SEO from '../components/seo';
import Container from '../components/container';
import BottomCut from '../components/bottomCut';
import AnimatedStripe from '../components/animatedStripe';
import Button from '../components/buttons';

// Asset Imports
import Stripes07SVG from '../images/stripes/stripes07.inline.svg';

// Styled Components
const PageStripes = styled(AnimatedStripe)`
  position: absolute;
  top: -20%;
  left: -40%;
  width: 100%;
  height: 200%;
  z-index: 1;
  pointer-events: none;

  @media (min-width: 64em) {
    top: 60%;
    left: -25%;
    height: 175%;
  }
`;

const SixteenNine = styled.div`
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadow.coloured};
  z-index: 2;
  margin-top: -13em;
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  @media (min-width: 64em) {
    margin-top: -20em;
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

const SixteenNineContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

class ThreeSixtyPage extends Component {
  componentDidMount() {
    if (typeof window.Sirv === 'undefined') {
      loadSirv().then(() => {
        window.Sirv.start();
      });
    } else {
      window.Sirv.start();
    }
  }

  render() {
    return (
      <>
        <SEO title='360' />
        <Section dark cuts='bottomCut'>
          <Container>
            <H1>360 Photography</H1>
            <PageSubtitle>
              Highlight your product from every angle. Click and Drag!
            </PageSubtitle>
          </Container>
          <BottomCut />
          <PageStripes direction='left' midPage triggerOnce>
            <Stripes07SVG />
          </PageStripes>
        </Section>
        <Section cuts='topAndBottomCut'>
          <Container>
            <SixteenNine>
              <SixteenNineContent>
                <div className='Sirv'>
                  <div data-src='https://rubberduck.sirv.com/Spins/Website%20360%20Sample/Website%20360%20Sample.spin'></div>
                </div>
              </SixteenNineContent>
            </SixteenNine>
            <Button
              type='primary'
              label='Learn More'
              arrow
              path='/contact'
              style={{ zIndex: 3 }}
            />
          </Container>
          <BottomCut dark />
        </Section>
      </>
    );
  }
}

export default ThreeSixtyPage;
