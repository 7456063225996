import React from 'react';
import styled, { css } from 'styled-components';

// Adds correct allowances for more/less padding for slashed cuts.
// 4vw accounts for 1/2 height of triangle cut.

function findPadding(cuts) {
  switch (cuts) {
    case 'topAndBottomCut':
      return css`
        padding-top: calc(var(--section-padding-sm) - 4vw);
        padding-bottom: calc(var(--section-padding-sm) + 4vw);

        @media (min-width: 1024px) {
          padding-top: calc(var(--section-padding-lg) - 4vw);
          padding-bottom: calc(var(--section-padding-lg) + 4vw);
        }
      `;
    case 'topCut':
      return css`
        padding-top: calc(var(--section-padding-sm) - 4vw);
        padding-bottom: var(--section-padding-sm);

        @media (min-width: 1024px) {
          padding-top: calc(var(--section-padding-lg) - 4vw);
          padding-bottom: var(--section-padding-lg);
        }
      `;
    case 'bottomCut':
      return css`
        padding-top: var(--section-padding-sm);
        padding-bottom: calc(var(--section-padding-sm) + 4vw);

        @media (min-width: 1024px) {
          padding-top: var(--section-padding-lg);
          padding-bottom: calc(var(--section-padding-lg) + 4vw);
        }
      `;
    case 'extSectBottomCut':
      return css`
        padding-bottom: calc(var(--section-padding-sm) + 4vw);

        @media (min-width: 1024px) {
          padding-bottom: calc(var(--section-padding-lg) + 4vw);
        }
      `;
    default:
      return css`
        padding-top: var(--section-padding-sm);
        padding-bottom: var(--section-padding-sm);

        @media (min-width: 1024px) {
          padding-top: var(--section-padding-lg);
          padding-bottom: var(--section-padding-lg);
        }
      `;
  }
}

const SectionEl = styled.section`
  /* border: 1px solid red; */
  position: relative;
  background-color: ${(props) =>
    (props.light && props.theme.color.white) ||
    (props.dark && props.theme.color.blue1) ||
    props.theme.color.blue6};
  ${(props) => findPadding(props.cuts)};
`;

export const Section = (props) => (
  <SectionEl
    data-background={
      (props.light && 'light') || (props.dark && 'dark') || 'normal'
    }
    {...props}
  >
    {props.children}
  </SectionEl>
);
