import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  //Default to accent but cater for Blue page titles
  color: ${props =>
    props.blue ? props.theme.color.blue : props.theme.color.accent};
`;
