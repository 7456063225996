// Load Sirv 360 viewer script
export default function loadSirv(components) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://scripts.sirv.com/sirvjs/v3/sirv.js';
    script.type = 'text/javascript';
    script.async = true;
    if (components) {
      script.dataset.components = components;
    }
    script.onload = resolve;
    document.body.appendChild(script);
  });
}
